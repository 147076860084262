<template>
  <div class="order-selected-results">
    <div class="package-wrapper">
      <a class="package-header no-hover"> Order Results </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="row">
          <div class="col-sm-6 col-left">
            <div v-if="ordersMadeSelected.result_files">
              <div class="mb-3 text-elipsis" :title="ordersMadeSelected.result_files.result_file.file_name">
                Current File: <span> {{ ordersMadeSelected.result_files.result_file.file_name }}</span>
              </div>
              <div class="upload-delete-wrapper">
                <a class="btn-info btn mr-3" @click="downloadResultFile" :disabled="preparingFileForDownload">
                  <span v-if="preparingFileForDownload"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                  <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download File</span>
                </a>
                <a class="btn-danger btn" @click="deleteResultFilePrompt" :disabled="preparingFileForDownload">
                  <span>Delete File</span>
                </a>
              </div>

              <div class="mt-4 small alert alert-warning">
                Result file will only be available for client if order has a status of "Order Completed".
              </div>
            </div>

            <div class="form-group" v-if="!ordersMadeSelected.result_files">
              <label class="mb-2">Results File:</label>
              <div class="input-group">
                <label class="input-group-btn">
                  <span class="btn btn-info btn-file">
                    <i class="glyphicon glyphicon-folder-open"></i>
                    <input type="file" id="file-upload" @change="onFileChange" class="btn-info btn" accept="application/pdf" />
                    <span class="ml-3">Upload result file</span>
                  </span>
                </label>
              </div>
              <div class="small mt-1" style="color: #aaaaaa">Allowed files types: pdf</div>

              <div class="clearfix"></div>
              <div v-if="resultsFile.fileName && resultsFile.fileName !== 'empty'" class="text-elipsis" :title="resultsFile.fileName">
                <hr />
                Current File: <span> {{ resultsFile.fileName }}</span>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div id="deleteResultFileModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Are you sure</h4>
          </div>
          <div class="modal-body">Click delete to remove result file from the order.</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="deleteResultFile()">Delete</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import * as helpers from '@/helpers/functions.js';

export default {
  name: 'OrderResults',
  data() {
    return {
      resultsFileToUpload: {
        fileName: null,
        file: null
      },
      preparingFileForDownload: false
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      getSelectedOrderData: 'getSelectedOrderData',
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      resultsFile: 'resultsFile'
    })
  },
  methods: {
    ...mapMutations({
      selectOrderMade: 'selectOrderMade'
    }),
    ...mapActions({
      selectOrderMade: 'selectOrderMade'
    }),
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > this.$store.state.config.maxFileSize) {
        this.$snotify.error(`File must be a PDF file and under 5MB in size`, '', {
          timeout: 4000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'rightTop'
        });
        document.getElementById('file-upload').value = '';
        return;
      } else {
        this.resultsFileToUpload.fileName = files[0].name;
        this.resultsFileToUpload.file = files[0];
        const payload = new FormData();
        payload.append('results_file', files[0]);
        payload.append('order_id', this.ordersMadeSelected.id);
        this.$store.commit('setTransLoader', true);
        this.$store.dispatch('uploadResultFile', payload).finally(() => this.$store.commit('setTransLoader', false));
      }
    },

    removeFile() {
      document.getElementById('file-upload').value = '';
    },
    downloadResultFile() {
      this.preparingFileForDownload = true;
      axios({
        url: `${this.$store.state.config.baseUrlApi}/api/download-resut-file`,
        data: {
          file_path: this.ordersMadeSelected.result_files.result_file.file_path,
          saved_file_name: this.ordersMadeSelected.result_files.result_file.saved_file_name,
          order_id: this.ordersMadeSelected.id
        },
        method: 'POST',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.ordersMadeSelected.result_files.result_file.file_name);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.preparingFileForDownload = false;
        });
    },
    deleteResultFilePrompt() {
      jQuery('#deleteResultFileModal')
        .appendTo('body')
        .modal('show');
    },
    deleteResultFile() {
      const payload = {
        file_path: this.ordersMadeSelected.result_files.result_file.file_path,
        saved_file_name: this.ordersMadeSelected.result_files.result_file.saved_file_name,
        order_id: this.ordersMadeSelected.id,
        file_table_name: 'result_files'
      };
      this.$store.dispatch('deleteFile', payload);
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {},
  destroyed: function() {
    helpers.removeModalFromBody('deleteResultFileModal');
  }
};
</script>
