<template>
  <!-- Modal -->
  <div id="confirmToExport" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Export PDF</h4>
        </div>
        <div class="modal-body">Would you like to export the invoice?</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="prepareForPdfExport()">Export to PDF</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
//import jsPDF from '@/node_modules/jspdf/dist/jspdf.min.js'
import { jsPDF } from 'jspdf';
//import autoTable from '@/node_modules/jspdf-autotable/dist/jspdf.plugin.autotable.min.js'
import autoTable from 'jspdf-autotable';
import { capitalizeFirstLetter } from '@/helpers/functions.js';

let base64Img;
let height;
let pageHeight;
let doc;

export default {
  name: 'OrderExportPDF',
  data() {
    return {
      image: null
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      ordersMadeSelectedPackages: 'ordersMadeSelectedPackages',
      invoiceData: 'invoiceData',
      ordersMadeSelected: 'ordersMadeSelected',
      currency: 'currency',
      taxes: 'taxes',
      getOrderData: 'getOrderData'
    })
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      getInvoiceData: 'getInvoiceData'
    }),
    async prepareForPdfExport() {
      if (!this.getOrderData.adminCosts) await this.getInvoiceData();
      this.exportOrderToPdf();
    },
    exportOrderToPdf() {
      doc = new jsPDF('portrait', 'mm', 'a4', false);
      this.imgToBase64('/img/logos/logo-web-nv.png', function(base64) {
        base64Img = base64;
      });

      setTimeout(() => {
        this.buildHeaderData();
        this.buildPackageData();
        this.buildAdditionalCostsData();
        this.buildTotalsData();
        setTimeout(() => doc.save('invoice_' + this.ordersMadeSelected.order_number + '.pdf'), 500);
      }, 500);
    },
    buildHeaderData() {
      height = 0;
      if (base64Img) {
        doc.addImage(base64Img, 'JPEG', doc.internal.pageSize.width - 57, 15, 43, 22);
      }
      doc.setFontSize(18);
      doc.text('Invoice:', 14, (height += 15));
      doc.setFontSize(10);
      doc.text(this.invoiceData.companyName, 14, (height += 6));
      // doc.text(this.invoiceData.contactPerson, 14, (height += 5));
      // doc.text(this.invoiceData.contactPersonTitle, 14, (height += 5));
      doc.text('Phone: ' + this.invoiceData.contactPersonTelephone, 14, (height += 5));
      doc.text('Email: ' + this.invoiceData.contactPersonEmail, 14, (height += 5));
      doc.text('Website: ' + this.invoiceData.companyWebsite, 14, (height += 5));
      doc.text(this.invoiceData.bankAccount, 14, (height += 5));
      doc.line(14, (height += 5), doc.internal.pageSize.width - 14, height);

      doc.setFontSize(16);
      doc.text('BILL TO:', 14, (height += 10));
      let heightCol = height;
      doc.setFontSize(10);
      doc.text('Company: ' + this.ordersMadeSelected.client.company_name, 14, (height += 5));
      doc.text('Contact Person: ' + this.ordersMadeSelected.client.first_name + ' ' + this.ordersMadeSelected.client.last_name, 14, (height += 5));
      doc.text('Address: ' + this.ordersMadeSelected.client.company_address + ', ARUBA', 14, (height += 5));
      doc.text('Telephone: ' + this.ordersMadeSelected.client.phone_number, 14, (height += 5));
      doc.text('Email: ' + this.ordersMadeSelected.client.email, 14, (height += 5));

      doc.setFontSize(12);
      doc.text('Invoice Number: ' + this.ordersMadeSelected.order_number, 130, (heightCol += 5));
      doc.text('Invoice Date: ' + this.ordersMadeSelected.order_data.orderInvoiceDate.formatted, 130, (heightCol += 5));
      doc.text('Invoice Due Date: ' + this.ordersMadeSelected.order_data.orderInvoiceDueDate, 130, (heightCol += 5));
      // doc.setFontSize(10);
      // doc.text('Service Date and Time:', 130, (heightCol += 5));
      // doc.text(helpers.formatDateTime(this.ordersMadeSelected.service_date_time), 130, (heightCol += 5));
      doc.setFontSize(14);
      doc.setFillColor(211, 211, 211);
      doc.rect(128, heightCol + 2.5, 65, 8, 'F');
      doc.text('Amount Due: ' + this.currency + this.ordersMadeSelected.order_data.orderTotalPriceWithTaxes, 130, (heightCol += 8));
    },
    buildPackageData() {
      var columns = [
        { title: '\nAnalysis', dataKey: 'analysis' },
        { title: '\nMethod', dataKey: 'method' },
        { title: '\nUnit', dataKey: 'unit' },
        { title: '\nPrice', dataKey: 'price' }
        //{title: "\nQuantity", dataKey: "quantity"},
        //{title: "\nTotal Price", dataKey: "total_price"}
      ];
      var packages = this.ordersMadeSelectedPackages;
      doc.setFontSize(15);

      for (var i = 0; i < packages.length; i++) {
        let rowsStandard = this.ordersMadeSelectedPackages[i].packageItems[0]
          .filter(item => {
            return item.selected === true;
          })
          .map(item => {
            return {
              analysis: item.name_analysis,
              method: item.method,
              unit: item.unit,
              price: item.price_awg
              //'quantity': item.quantity,
              //'total_price': item.total_item_price
            };
          });
        let rowsExtra = this.ordersMadeSelectedPackages[i].packageItems[1]
          .filter(item => {
            return item.selected === true;
          })
          .map(item => {
            return {
              analysis: item.name_analysis,
              method: item.method,
              unit: item.unit,
              price: item.price_awg
              // quantity: item.quantity,
              // total_price: item.total_item_price,
            };
          });
        if (i == 0) {
          doc.text(this.ordersMadeSelectedPackages[i].name, 14, (height += 20));
          doc.setFontSize(10);
          if (this.ordersMadeSelectedPackages[i].serviceAddress)
            doc.text('Sampling Location: ' + this.ordersMadeSelectedPackages[i].serviceAddress, 14, (height += 5));
          if (this.ordersMadeSelectedPackages[i].samplePoint)
            doc.text('Sample Point: ' + this.ordersMadeSelectedPackages[i].samplePoint, 14, (height += 5));
          if (this.ordersMadeSelectedPackages[i].sampleDoneByWebLab)
            doc.text('Sample done by WEB LAB: ' + capitalizeFirstLetter(this.ordersMadeSelectedPackages[i].sampleDoneByWebLab), 14, (height += 5));
          if (this.ordersMadeSelectedPackages[i].packageSpecialRequests)
            doc.text('Requests: ' + this.ordersMadeSelectedPackages[i].packageSpecialRequests, 14, (height += 5));
          columns[0].title = 'Standard Tests\nAnalysis';
          doc.autoTable({ columns: columns, body: rowsStandard, startY: (height += 3), pageBreak: 'auto', showHead: 'firstPage' });
          doc.setFillColor(211, 211, 211);
          doc.rect(14, doc.autoTable.previous.finalY, doc.internal.pageSize.width - 28, 8, 'F');
          doc.text(
            this.currency + ' ' + this.ordersMadeSelectedPackages[i].packageItemTotals[0],
            doc.internal.pageSize.width - 45,
            doc.autoTable.previous.finalY + 5.5
          );
          if (rowsExtra.length && rowsExtra) {
            columns[0].title = 'Microbiological Tests\n Analysis';
            doc.autoTable({
              columns: columns,
              body: rowsExtra,
              startY: doc.autoTable.previous.finalY + 13,
              pageBreak: 'auto',
              showHead: 'firstPage'
            });
            doc.setFillColor(211, 211, 211);
            doc.rect(14, doc.autoTable.previous.finalY, doc.internal.pageSize.width - 28, 8, 'F');
            doc.text(
              this.currency + ' ' + this.ordersMadeSelectedPackages[i].packageItemTotals[1],
              doc.internal.pageSize.width - 45,
              doc.autoTable.previous.finalY + 5.5
            );
          }
        } else {
          height = doc.autoTable.previous.finalY;
          pageHeight = doc.internal.pageSize.height;
          if (height + 50 >= pageHeight) {
            doc.addPage();
            height = -10;
          }
          doc.setFontSize(15);
          doc.text(this.ordersMadeSelectedPackages[i].name, 14, (height += 25));
          doc.setFontSize(12);
          if (this.ordersMadeSelectedPackages[i].serviceAddress)
            doc.text('Sampling Location: ' + this.ordersMadeSelectedPackages[i].serviceAddress, 14, (height += 5));
          if (this.ordersMadeSelectedPackages[i].samplePoint)
            doc.text('Sample Point: ' + this.ordersMadeSelectedPackages[i].samplePoint, 14, (height += 5));
          if (this.ordersMadeSelectedPackages[i].packageSpecialRequests)
            doc.text('Requests: ' + this.ordersMadeSelectedPackages[i].packageSpecialRequests, 14, (height += 5));
          columns[0].title = 'Standard Tests\nAnalysis';
          doc.autoTable({ columns: columns, body: rowsStandard, startY: (height += 3), pageBreak: 'auto', showHead: 'firstPage' });
          doc.setFillColor(211, 211, 211);
          doc.rect(14, doc.autoTable.previous.finalY, doc.internal.pageSize.width - 28, 8, 'F');
          doc.text(
            this.currency + ' ' + this.ordersMadeSelectedPackages[i].packageItemTotals[0],
            doc.internal.pageSize.width - 45,
            doc.autoTable.previous.finalY + 5.5
          );

          if (rowsExtra.length && rowsExtra) {
            columns[0].title = 'Microbiological Tests\nAnalysis';
            doc.autoTable({
              columns: columns,
              body: rowsExtra,
              startY: doc.autoTable.previous.finalY + 13,
              pageBreak: 'auto',
              showHead: 'firstPage'
            });
            doc.setFillColor(211, 211, 211);
            doc.rect(14, doc.autoTable.previous.finalY, doc.internal.pageSize.width - 28, 8, 'F');
            doc.text(
              this.currency + ' ' + this.ordersMadeSelectedPackages[i].packageItemTotals[1],
              doc.internal.pageSize.width - 45,
              doc.autoTable.previous.finalY + 5.5
            );
          }
        }
      } //endfor
    },
    buildAdditionalCostsData() {
      if (!this.ordersMadeSelected.order_data.additionalCosts[0].cost) return;
      height = doc.autoTable.previous.finalY;
      pageHeight = doc.internal.pageSize.height;
      if (height + 50 >= pageHeight) {
        doc.addPage();
        height = 0;
      }
      var columns = [
        { title: 'Description', dataKey: 'description', width: 10 },
        { title: 'Hour Rate', dataKey: 'hour_rate' },
        { title: 'Hours', dataKey: 'hours' },
        { title: 'Cost', dataKey: 'cost' }
      ];

      let rows = this.ordersMadeSelected.order_data.additionalCosts.map(item => {
        return {
          description: item.description,
          hour_rate: item.hourRate,
          hours: item.hours,
          cost: item.cost
        };
      });

      doc.setFontSize(15);
      doc.text('Additional Costs', 14, (height += 25));
      doc.autoTable({
        columns: columns,
        body: rows,
        startY: (height += 3),
        pageBreak: 'auto',
        showHead: 'firstPage',
        styles: { overflow: 'linebreak' },
        columnStyles: { description: { cellWidth: 120 } }
      });
      doc.setFillColor(211, 211, 211);
      doc.rect(14, doc.autoTable.previous.finalY, doc.internal.pageSize.width - 28, 8, 'F');
      doc.setFontSize(12);
      doc.text(
        this.currency + ' ' + this.ordersMadeSelected.order_data.additionalCostsTotal,
        doc.internal.pageSize.width - 45,
        doc.autoTable.previous.finalY + 5.5
      );
    },
    buildTotalsData() {
      height = doc.autoTable.previous.finalY;
      pageHeight = doc.internal.pageSize.height;
      if (height + 70 >= pageHeight) {
        doc.addPage();
        height = 0;
      }
      doc.setDrawColor(0, 0, 0);
      height += 20;
      doc.line(14, height, doc.internal.pageSize.width - 14, height);
      doc.setFontSize(12);
      doc.text(
        'Order Price: ' + this.currency + ' ' + this.ordersMadeSelected.order_data.orderTotalPrice,
        doc.internal.pageSize.width - 70,
        (height += 8)
      );
      doc.text(
        'Admin Costs: ' + this.currency + ' ' + this.ordersMadeSelected.order_data.adminCosts.toFixed(2),
        doc.internal.pageSize.width - 70,
        (height += 7)
      );
      // doc.text(
      //   'BBO (' + this.taxes.bbo + '%): ' + this.currency + ' ' + this.ordersMadeSelected.order_data.orderTotalPriceBBO,
      //   doc.internal.pageSize.width - 70,
      //   (height += 7)
      // );
      // doc.text(
      //   'BAZV (' + this.taxes.bazv + '%): ' + this.currency + ' ' + this.ordersMadeSelected.order_data.orderTotalPriceBAZV,
      //   doc.internal.pageSize.width - 70,
      //   (height += 7)
      // );
      // doc.text(
      //   'BAVP (' + this.taxes.bazv + '%): ' + this.currency + ' ' + this.ordersMadeSelected.order_data.orderTotalPriceBAVP,
      //   doc.internal.pageSize.width - 70,
      //   (height += 7)
      // );
      doc.setFontSize(17);
      doc.setFillColor(211, 211, 211);
      doc.rect(14, (height += 4), doc.internal.pageSize.width - 28, 10, 'F');
      doc.text(
        'Amount Due: ' + this.currency + ' ' + this.ordersMadeSelected.order_data.orderTotalPriceWithTaxes,
        doc.internal.pageSize.width - 90,
        (height += 7)
      );
    },
    imgToBase64(url, callback) {
      if (!window.FileReader) {
        callback(null);
        return;
      }
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result.replace('text/xml', 'image/jpeg'));
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
