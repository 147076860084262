<template>
  <div class="order-invoice-wrapper">
    <div class="package-wrapper">
      <a class="package-header no-hover"> Order Payment </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="order-selected-results weblab-form">
          <div class="alert alert-warning mb-4" v-if="isClient && ordersMadeSelectedStateSlug == 'invoice_ready'">
            Download your invoice and then make payment to the bank account listed in the invoice. Upload proof of payment (image or PDF).
            <div class="mt-2">
              <strong>Once payment has been received, you will proceed to the next step, which is to plan an appointment date and time.</strong>
            </div>
          </div>
          <div class="row">
            <!-- INVOICE SECTION -->
            <div class="col-sm-6 col-left">
              <h4 class="mb-2">Invoice</h4>
              <hr class="mt-1 mb-3" />
              <div v-if="!ordersMadeSelected.invoice_files && (isAccounting || isDashboardAdmin)" class="mt-3">
                <div class="form-group">
                  <div class="input-group">
                    <label class="input-group-btn">
                      <span class="btn btn-info btn-file">
                        <i class="glyphicon glyphicon-folder-open"></i>
                        <input type="file" id="invoice-file-upload" @change="onInvoiceFileChange" class="btn-info btn" accept="application/pdf" />
                        <span class="ml-3">Upload invoice</span>
                      </span>
                    </label>
                  </div>
                  <div class="small mt-1" style="color: #aaaaaa">Allowed files types: pdf</div>
                </div>
              </div>

              <div v-if="invoiceFileInOrder">
                <div v-if="isDashboardAdmin || isAccounting">
                  <div class="mb-3 text-elipsis" :title="invoiceFileInOrder.file_name">
                    Current File: <span> {{ invoiceFileInOrder.file_name }}</span>
                  </div>
                  <div class="upload-delete-wrapper">
                    <a class="btn-info btn" @click="downloadInvoiceFile" :disabled="preparingFileForSave">
                      <span v-if="preparingFileForSave"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                      <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download File</span>
                    </a>
                    <a class="btn-danger btn" @click="deleteFilePrompt('invoice')">
                      <span>Delete File</span>
                    </a>
                  </div>
                </div>
                <div v-else>
                  <div class="alert alert-info">
                    <div class="mb-3">Invoice ready for download</div>

                    <a class="btn-info btn w-100" @click="downloadInvoiceFile" :disabled="preparingFileForSave">
                      <span v-if="preparingFileForSave"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                      <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download File</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- UPLOAD PROOF OF PAYMENT SECTION -->
            <div class="col-sm-6" v-if="isClient || isDashboardAdmin">
              <h4 class="mb-2">Proof of payment</h4>
              <hr class="mt-1 mb-3" />

              <div v-if="!ordersMadeSelected.proof_of_payment_files" class="mt-3">
                <div class="form-group">
                  <div class="input-group">
                    <label class="input-group-btn">
                      <span class="btn btn-info btn-file">
                        <i class="glyphicon glyphicon-folder-open"></i>
                        <input
                          type="file"
                          id="proof-of-payment-file-upload"
                          @change="onProofOfPaymentFileChange"
                          class="btn-info btn"
                          accept="image/*,.pdf"
                          ref="fileInput"
                        />
                        <span class="ml-3">Upload proof of payment</span>
                      </span>
                    </label>
                  </div>
                  <div class="small mt-1" style="color: #aaaaaa">Allowed files types: images and pdf</div>
                </div>
              </div>

              <div v-if="proofOfPaymentFileInOrder">
                <div v-if="isDashboardAdmin">
                  <div class="mb-3 text-elipsis" :title="proofOfPaymentFileInOrder.file_name">
                    Current File: <span> {{ proofOfPaymentFileInOrder.file_name }}</span>
                  </div>
                  <div class="upload-delete-wrapper">
                    <a class="btn-info btn mr-3" @click="downloadProofOfPaymentFile" :disabled="preparingFileForSave">
                      <span v-if="preparingFileForSave"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                      <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download File</span>
                    </a>
                    <a class="btn-danger btn" @click="deleteFilePrompt('proofOfPayment')">
                      <span>Delete File</span>
                    </a>
                  </div>
                </div>
                <div v-else-if="isClient && ordersMadeSelected.order_state.order > 4" class="alert alert-info ">
                  <div class="mb-3">Proof of payment file</div>
                  <a class="btn-info btn w-100" @click="downloadProofOfPaymentFile" :disabled="preparingFileForSave">
                    <span v-if="preparingFileForSave"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                    <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download File</span>
                  </a>
                </div>
                <div v-else>
                  <div class="alert alert-success" role="alert">
                    <strong class="mb-1">File submitted:</strong>
                    <div>Your file has been submitted for review.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal proof of payment-->
    <div id="uploadProofOfPaymentModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Upload proof of payment</h4>
          </div>
          <div class="modal-body">
            Click on upload to submit your proof of payment for review. <br />

            <div class="file-name mt-4 mb-4">
              <div class="label-check">File to submit</div>
              <div style="font-weight: bold;" class="text-elipsis small text-max-width">
                {{ proofPaymentFileToUpload.fileName }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="uploadProofOfPayment()">Upload file</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->

    <!-- Delete Modal -->
    <div id="deleteFileModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Are you sure</h4>
          </div>
          <div class="modal-body">Click delete to remove result file from the order.</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
            <button v-if="typeOfFileToDelete == 'invoice'" type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteInvoiceFile()">
              Delete
            </button>
            <button
              v-if="typeOfFileToDelete == 'proofOfPayment'"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteProofOfPaymentFile()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as helpers from '@/helpers/functions.js';
import axios from 'axios';
export default {
  computed: {
    proofOfPaymentFileInOrder() {
      return this.ordersMadeSelected.proof_of_payment_files?.proof_of_payment_file ?? null;
    },
    invoiceFileInOrder() {
      return this.ordersMadeSelected.invoice_files?.invoice_file ?? null;
    },
    ...mapGetters({
      invoiceData: 'invoiceData',
      isDashboardAdmin: 'isDashboardAdmin',
      ordersMadeSelected: 'ordersMadeSelected',
      isAccounting: 'isAccounting',
      isClient: 'isClient',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug'
    })
  },
  mounted() {
    // $('#uploadProofOfPaymentModal').on('hidden.bs.modal', () => {
    //   helpers.removeModalFromBody('uploadProofOfPaymentModal');
    //   console.log('modal closed');
    //   // Add any additional code you want to run when the modal closes here
    // });
  },
  data() {
    return {
      helpers,
      preparingFileForSave: false,
      proofPaymentFileToUpload: {
        fileName: null,
        file: null
      },
      invoicefileToUpload: {
        fileName: null,
        file: null
      },
      typeOfFileToDelete: null
    };
  },
  methods: {
    onProofOfPaymentFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > this.$store.state.config.maxFileSize) {
        this.$snotify.error(`File must be a PDF or image file and under 5MB in size`, '', {
          timeout: 4000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'rightTop'
        });
        document.getElementById('proof-of-payment-file-upload').value = '';
        return;
      } else {
        this.proofPaymentFileToUpload.file = files[0];
        this.proofPaymentFileToUpload.fileName = files[0].name;
        if (!this.isDashboardAdmin) {
          jQuery('#uploadProofOfPaymentModal')
            .appendTo('body')
            .modal('show');
        } else {
          this.uploadProofOfPayment();
        }
      }
      this.$refs.fileInput.value = '';
    },
    uploadProofOfPayment() {
      //upload on order save
      const payload = new FormData();
      payload.append('proof_of_payment_file', this.proofPaymentFileToUpload.file);
      payload.append('order_id', this.ordersMadeSelected.id);
      this.$store.commit('setTransLoader', true);
      this.$store.dispatch('uploadProofOfPaymentFile', payload).finally(() => this.$store.commit('setTransLoader', false));
    },
    deleteFilePrompt(typeOfFileToDelete) {
      this.typeOfFileToDelete = typeOfFileToDelete;
      jQuery('#deleteFileModal')
        .appendTo('body')
        .modal('show');
    },
    downloadProofOfPaymentFile() {
      this.preparingFileForSave = true;
      axios({
        url: `${this.$store.state.config.baseUrlApi}/api/download-proof-of-payment-file`,
        data: {
          file_path: this.proofOfPaymentFileInOrder.file_path,
          saved_file_name: this.proofOfPaymentFileInOrder.saved_file_name,
          order_id: this.ordersMadeSelected.id
        },
        method: 'POST',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.proofOfPaymentFileInOrder.file_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.preparingFileForSave = false;
        });
    },
    deleteProofOfPaymentFile() {
      $('#deleteFileModal').modal('hide');
      debugger;
      const payload = {
        file_path: this.proofOfPaymentFileInOrder.file_path,
        saved_file_name: this.proofOfPaymentFileInOrder.saved_file_name,
        order_id: this.ordersMadeSelected.id,
        file_table_name: 'proof_of_payment_files'
      };
      this.$store.commit('setTransLoader', true);
      this.$store.dispatch('deleteFile', payload).finally(() => this.$store.commit('setTransLoader', false));
    },
    onInvoiceFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > this.$store.state.config.maxFileSize) {
        this.$snotify.error(`File must be in a PDF format and under 5MB in size`, '', {
          timeout: 4000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'rightTop'
        });
        document.getElementById('invoice-file-upload').value = '';
        return;
      } else {
        this.invoicefileToUpload.file = files[0];
        this.invoicefileToUpload.fileName = files[0].name;

        this.uploadInvoice();
      }
    },
    uploadInvoice() {
      //upload on order save
      const payload = new FormData();
      payload.append('invoice_file', this.invoicefileToUpload.file);
      payload.append('order_id', this.ordersMadeSelected.id);
      this.$store.commit('setTransLoader', true);
      this.$store.dispatch('uploadInvoiceFile', payload).finally(() => this.$store.commit('setTransLoader', false));
    },
    downloadInvoiceFile() {
      this.preparingFileForSave = true;
      axios({
        url: `${this.$store.state.config.baseUrlApi}/api/download-invoice-file`,
        data: {
          file_path: this.invoiceFileInOrder.file_path,
          saved_file_name: this.invoiceFileInOrder.saved_file_name,
          order_id: this.ordersMadeSelected.id
        },
        method: 'POST',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.invoiceFileInOrder.file_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.preparingFileForSave = false;
        });
    },
    deleteInvoiceFile() {
      const payload = {
        file_path: this.invoiceFileInOrder.file_path,
        saved_file_name: this.invoiceFileInOrder.saved_file_name,
        order_id: this.ordersMadeSelected.id,
        file_table_name: 'invoice_files'
      };
      this.$store.commit('setTransLoader', true);
      this.$store.dispatch('deleteFile', payload).finally(() => this.$store.commit('setTransLoader', false));
    }
  },
  destroyed: function() {
    helpers.removeModalFromBody('uploadProofOfPaymentModal');
    helpers.removeModalFromBody('deleteFileModal');
  }
};
</script>

<style>
.file-name {
  background: #fbffd4;
  font-weight: 600;
  margin-top: 10px;
  padding: 6px 10px;
}
</style>
