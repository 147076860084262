<template>
  <a href="#" @click.prevent="confirmToExport()" v-if="isAdmin || isDashboardAdmin">
    <i class="glyphicon glyphicon-file"></i> Export invoice to PDF
    <OrderExportPDF />
  </a>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import OrderExportPDF from '../../../../generic/OrderExportPDF.vue';

export default {
  name: 'OrderExportPdfButton',
  data() {
    return {
      image: null
    };
  },
  components: { OrderExportPDF },
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      isAdmin: 'isAdmin',
      isDashboardAdmin: 'isDashboardAdmin'
    })
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    confirmToExport() {
      //check for data missing
      let dataMissing = this.checkForMissingData();
      if (dataMissing) return;
      jQuery('#confirmToExport')
        .appendTo('body')
        .modal('show');
    },
    checkForMissingData() {
      let orderData = this.ordersMadeSelected;
      let warningText;

      if (!orderData.service_date_time) {
        warningText = 'Please set a service data and time';
        this.showMissingDataWarning(warningText);
        return true;
      }
      //add additional checks if needed

      return false;
    },
    showMissingDataWarning(warningText) {
      this.$snotify.warning(warningText, '', {
        timeout: 5000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop'
      });
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
