<template>
  <div class="order-selected-packages">
    <h2 class="section-title">Order Info</h2>
    <!-- PACKAGES -->
    <div
      v-for="(packageItem, index) in ordersMadeSelectedPackages"
      :key="packageItem.selectedPackage.package_code + '-' + index"
      class="package-wrapper"
    >
      <!-- PACKAGE -->
      <div class="package-header">Package: {{ packageItem.name }}</div>
      <div class="package-body panel-collapse">
        <!-- PACKAGE DETAILS -->
        <table class="table table-bordered text-caption table-condensed mt-2 mb-4">
          <tbody>
            <tr>
              <td class="small-column">Sample location</td>
              <td>
                <input
                  class="form-control"
                  :value="ordersMadeSelectedPackages[index].serviceAddress"
                  type="text"
                  v-on:keyup="setServiceAddressLocal($event.target.value, index)"
                  v-if="ordersMadeSelectedData.edit"
                />
                <div v-else>
                  {{ ordersMadeSelectedPackages[index].serviceAddress ? ordersMadeSelectedPackages[index].serviceAddress : 'None' }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="small-column">Sample point</td>
              <td>
                <input
                  class="form-control"
                  :value="ordersMadeSelectedPackages[index].samplePoint"
                  type="text"
                  v-on:keyup="setSamplePointLocal($event.target.value, index)"
                  v-if="ordersMadeSelectedData.edit"
                />
                <div v-else>
                  {{ ordersMadeSelectedPackages[index].samplePoint ? ordersMadeSelectedPackages[index].samplePoint : 'None' }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="small-column">Sample done by WEB LAB</td>
              <td>
                <select
                  v-if="ordersMadeSelectedData.edit"
                  id="different-sampling-address"
                  :value="ordersMadeSelectedPackages[index].sampleDoneByWebLab"
                  class="form-control"
                  @change="setSampleDoneByWebLabLocal($event.target.value, index)"
                >
                  <option :value="null" disabled>Select</option>
                  <option :value="'yes'">Yes</option>
                  <option :value="'no'">No</option>
                </select>
                <div v-else class="text-capitalize">
                  {{ ordersMadeSelectedPackages[index].sampleDoneByWebLab ? ordersMadeSelectedPackages[index].sampleDoneByWebLab : 'None' }}
                </div>
              </td>
            </tr>

            <tr>
              <td class="small-column">Additional Requests or Comments</td>
              <td>
                <textarea
                  v-if="ordersMadeSelectedData.edit"
                  class="form-control additional-message"
                  name="additional-message"
                  :value="ordersMadeSelectedPackages[index].packageSpecialRequests"
                  @keyup="setPackageSpecialRequestsLocal($event.target.value, index)"
                >
                </textarea>
                <div v-else>
                  <span v-if="ordersMadeSelectedPackages[index].packageSpecialRequests">
                    {{ ordersMadeSelectedPackages[index].packageSpecialRequests }}
                  </span>
                  <span v-else>No additional requests</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Standard tests -->
        <a
          data-toggle="collapse"
          :href="'#collapse-' + index + '-standard-tests'"
          class="package-tests-header collapsed"
          title="Expand/Collapse package details"
        >
          <i class="glyphicon glyphicon-plus-sign pull-right"></i>
          Standard Tests
          <small style="font-size: 12px; float: right; margin-right: 10px">ClICK TO EXPAND</small>
        </a>
        <div :id="'collapse-' + index + '-standard-tests'" class="panel-collapse collapse panel-collapse-whitesmoke">
          <div class="price-warning table-slide">Slide table to right to view all columns</div>
          <div class="table-responsive">
            <table class="table table-hover table-standard-tests table-tests">
              <thead>
                <tr>
                  <th v-if="ordersMadeSelectedData.edit">Selected</th>
                  <th>Analysis</th>
                  <th>Method</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <!-- <th>Quantity</th> -->
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, indexItems) in packageItem.packageItems[0]" :key="item.id + '-standard-items' + indexItems">
                  <template v-if="item.selected || ordersMadeSelectedData.edit">
                    <td class="checkbox-td" v-if="ordersMadeSelectedData.edit">
                      <MaterialCheckbox
                        class="material-checkbox"
                        :selected="item.selected"
                        @checkboxChanged="checkBoxClicked($event, 0, index, indexItems)"
                      >
                      </MaterialCheckbox>
                    </td>
                    <td>{{ item.name_analysis }}</td>
                    <td>{{ item.method }}</td>
                    <td v-if="item.measurement_unit && item.measurement_unit.measurement_unit">{{ item.measurement_unit.measurement_unit }}</td>
                    <td v-else class="lightgrey">NA</td>
                    <td>{{ item.price_awg }}</td>
                    <!-- <td>
                          <select v-model="item.quantity" v-if="ordersMadeSelectedData.edit"
                            @change="getQuantityTotalSelectedOrderMade({'quantity':item.quantity, 'packageIndex':index})">
                            <option v-for="quantity in quantitySelect" :key="quantity" :value="quantity"> {{quantity}}</option>
                          </select>
                          <div v-if="!ordersMadeSelectedData.edit">{{ item.quantity }}</div>
                        </td> -->
                    <td>{{ item.total_item_price }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="table-row-totals">{{ currency }} {{ ordersMadeSelectedPackages[index].packageItemTotals[0] }}</div>

        <!-- Micro biological tests -->
        <a
          data-toggle="collapse"
          :href="'#collapse-' + index + '-microbio-tests'"
          class="package-tests-header collapsed"
          title="Expand/Collapse package details"
        >
          <i class="glyphicon glyphicon-plus-sign pull-right"></i>
          Microbiological Tests
          <small style="font-size: 12px; float: right; margin-right: 10px" class="expand-btn">ClICK TO EXPAND</small>
        </a>
        <div :id="'collapse-' + index + '-microbio-tests'" class="panel-collapse collapse">
          <template v-if="packageItem.packageItems[1].some(item => item.selected) || ordersMadeSelectedData.edit">
            <div class="price-warning table-slide">Slide table to right to view all columns</div>
            <div class="table-responsive extra-table-wrapper">
              <table class="table table-hover table-standard-tests table-tests">
                <thead>
                  <tr>
                    <th v-if="ordersMadeSelectedData.edit">Selected</th>
                    <th>Analysis</th>
                    <th>Method</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <!-- <th>Quantity</th> -->
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, indexItems) in packageItem.packageItems[1]" :key="item.id + '-extra-items-' + indexItems">
                    <template v-if="item.selected || ordersMadeSelectedData.edit">
                      <td class="checkbox-td" v-if="ordersMadeSelectedData.edit">
                        <MaterialCheckbox
                          class="material-checkbox"
                          :selected="item.selected"
                          @checkboxChanged="checkBoxClicked($event, 1, index, indexItems)"
                        >
                        </MaterialCheckbox>
                      </td>
                      <td>{{ item.name_analysis }}</td>
                      <td>{{ item.method }}</td>
                      <td v-if="item.unit">{{ item.unit }}</td>
                      <td v-else class="lightgrey">NA</td>
                      <td>{{ item.price_awg }}</td>
                      <!-- <td>
                            <select v-model="item.quantity" v-if="ordersMadeSelectedData.edit"
                              @change="getQuantityTotalSelectedOrderMade({'quantity':item.quantity, 'packageIndex':index})">
                              <option v-for="quantity in quantitySelect" :key="quantity" :value="quantity"> {{quantity}}</option>
                            </select>
                            <div v-if="!ordersMadeSelectedData.edit">{{ item.quantity }}</div>
                          </td> -->
                      <td>{{ item.total_item_price }}</td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <div v-else class="alert alert-warning mt-3">
            No microbiological tests selected
          </div>
        </div>
        <div class="table-row-totals">{{ currency }} {{ ordersMadeSelectedPackages[index].packageItemTotals[1] }}</div>
      </div>
      <div class="total-selected ">
        <span class="pull-right">
          <span class="text-caption mr-1" style="color:grey"> Package Cost:</span> {{ currency }}
          {{ ordersMadeSelectedPackages[index].packageItemTotals[2] }}</span
        >
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <!-- end panel -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MaterialCheckbox from '@/components/generic/MaterialCheckbox.vue';

export default {
  name: 'OrderPackagesDisplay',
  data() {
    return {
      quantitySelect: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  components: { MaterialCheckbox },
  computed: {
    ...mapGetters({
      ordersMadeSelectedPackages: 'ordersMadeSelectedPackages',
      getSelectedOrderData: 'getSelectedOrderData',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      ordersMadeSelected: 'ordersMadeSelected',
      currency: 'currency'
    })
  },
  methods: {
    ...mapActions({
      setPackageItemSelectedOrderMade: 'setPackageItemSelectedOrderMade',
      getQuantityTotalSelectedOrderMade: 'getQuantityTotalSelectedOrderMade',
      setServiceAddressSelectedOrderMade: 'setServiceAddressSelectedOrderMade',
      setSamplePointSelectedOrderMade: 'setSamplePointSelectedOrderMade',
      setPackageSpecialRequestsOrderMade: 'setPackageSpecialRequestsOrderMade',
      setSampleDoneByWebLabSelectedOrderMade: 'setSampleDoneByWebLabSelectedOrderMade'
    }),
    checkBoxClicked(e, type, packageIndex, itemIndex) {
      this.setPackageItemSelectedOrderMade({ e, type, packageIndex, itemIndex });
    },
    setServiceAddressLocal(serviceAddress, packageIndex) {
      this.setServiceAddressSelectedOrderMade({ serviceAddress, packageIndex });
    },
    setSamplePointLocal(samplePoint, packageIndex) {
      this.setSamplePointSelectedOrderMade({ samplePoint, packageIndex });
    },
    setPackageSpecialRequestsLocal(request, packageIndex) {
      this.setPackageSpecialRequestsOrderMade({ request, packageIndex });
    },
    setSampleDoneByWebLabLocal(sampleDoneByWebLab, packageIndex) {
      this.setSampleDoneByWebLabSelectedOrderMade({ sampleDoneByWebLab, packageIndex });
    }
  }
};
</script>

<style scoped>
.small-column {
  max-width: 60px;
}
</style>
