<template>
  <div class="package-wrapper">
    <div class="package-header">Order Comment</div>
    <div class="panel-collapse">
      <div class="order-additional-costs">
        <!-- no comments yet message -->
        <div v-if="!ordersMadeSelectedData.edit && !getSelectedOrderData.orderComment" class="alert alert-warning">
          No comments have been added to this order
        </div>

        <template v-else>
          <div v-if="ordersMadeSelectedData.edit">
            <textarea
              rows="6"
              class="form-control description"
              :value="getSelectedOrderData.orderComment"
              @change="setOrderCommentLocal($event.target.value)"
              type="text"
              placeholder="Fill in comment"
            ></textarea>
          </div>
          <div v-else v-html="getSelectedOrderData.orderComment" style="white-space:pre-wrap"></div>
        </template>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderComment',
  computed: {
    ...mapGetters({
      getSelectedOrderData: 'getSelectedOrderData',
      ordersMadeSelectedData: 'ordersMadeSelectedData'
    })
  },
  methods: {
    ...mapMutations({
      setOrderComment: 'setOrderComment'
    }),
    setOrderCommentLocal(data) {
      if (data) {
        this.setOrderComment(data);
      } else return;
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
