<template>
  <div class="form-group assigned-worker" v-if="resetAssWorkerDisplay">
    <label class="order-state-admin-label">Assigned Worker</label>
    <div v-if="!dashboardAdmins.length && isDashboardAdmin" class="loader-medium"></div>
    <div v-if="!ordersMadeSelectedData.edit">
      <div v-if="ordersMadeSelected.assigned_worker" class="data-display clear-both-display">
        {{ ordersMadeSelected.assigned_worker.first_name }} {{ ordersMadeSelected.assigned_worker.last_name }}
      </div>
      <div v-else class="data-display clear-both-display">None</div>
    </div>

    <div style="position: relative">
      <select
        id="select-worker"
        class="form-control"
        v-model="ordersMadeSelected.assigned_worker_id"
        :disabled="!ordersMadeSelectedData.edit"
        v-if="ordersMadeSelectedData.edit && dashboardAdmins.length"
        @input="updateAssignedWorkerLocal($event.target.value)"
      >
        <option disabled :value="null" :key="null">Select worker</option>
        <option v-for="admin in dashboardAdmins" :key="admin.id" :value="admin.id">{{ admin.first_name }} {{ admin.last_name }}</option>
      </select>
      <button
        @click="clearAssignedWorkerLocal()"
        title="Clear Worker"
        v-if="ordersMadeSelected.assigned_worker_id && ordersMadeSelectedData.edit"
        class="btn-sm btn btn-default btn-clear-assworker"
      >
        &#10005;
      </button>
    </div>
    <transition name="scale">
      <div v-if="showEmailAssignWorker && ordersMadeSelectedData.edit">
        <MaterialCheckbox
          class="material-checkbox assworker mt-2"
          :selected="ordersMadeSelected.order_data.sentEmailAssWorker"
          @checkboxChanged="checkBoxClickedAssWorkerEmail($event)"
          label="Sent email to assigned worker"
        />
        <div class="text-small">Email notification will be sent to assigned worker when client has approved order.</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MaterialCheckbox from '@/components/generic/MaterialCheckbox.vue';
export default {
  props: ['showEmailAssignWorker'],
  components: { MaterialCheckbox },
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedCached: 'ordersMadeSelectedCached',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      isDashboardAdmin: 'isDashboardAdmin',
      dashboardAdmins: 'dashboardAdmins',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      dashboardAdmins: 'dashboardAdmins'
    })
  },
  data() {
    return {
      resetAssWorkerDisplay: true
    };
  },
  created() {
    if (this.dashboardAdmins.length === 0 && this.isDashboardAdmin) this.getDashboardAdmins();
  },
  methods: {
    ...mapMutations({
      clearAssignedWorker: 'clearAssignedWorker',
      checkBoxClickedAssWorkerEmail: 'checkBoxClickedAssWorkerEmail'
    }),
    ...mapActions({
      updateAssignedWorker: 'updateAssignedWorker',
      getDashboardAdmins: 'getDashboardAdmins'
    }),
    clearAssignedWorkerLocal() {
      //this.checkBoxClickedAssWorkerEmail(false);
      this.resetAssWorkerDisplay = false;
      setTimeout(() => {
        this.resetAssWorkerDisplay = true;
        this.clearAssignedWorker();
      }, 100);
    },
    updateAssignedWorkerLocal(newId) {
      let oldId = this.ordersMadeSelectedCached.assigned_worker_id;
      if (newId != oldId) {
        this.checkBoxClickedAssWorkerEmail(true);
      }

      let assignedWorker = this.dashboardAdmins.filter(worker => {
        return worker.id == newId;
      });

      this.updateAssignedWorker(assignedWorker[0]);
    }
  }
};
</script>

<style></style>
