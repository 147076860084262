<template>
  <transition name="scale">
    <div class="bs-wizard">
      <div class="bs-wizard-step" v-for="step in statesData" :key="step.name" :class="[step.class]">
        <!-- <div class="text-center bs-wizard-stepnum">{{ index + 1}}</div> -->
        <div class="progress"><div class="progress-bar"></div></div>
        <span class="bs-wizard-dot"></span>
        <div class="bs-wizard-info text-center">{{ step.name }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderStatesPearls',
  data() {
    return {
      statesData: []
    };
  },
  computed: {
    ...mapGetters({
      currentStep: 'currentStep',
      ordersMadeSelected: 'ordersMadeSelected',
      getEditMode: 'getEditMode',
      orderStates: 'orderStates',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug',
      ordersMadeSelectedStateId: 'ordersMadeSelectedStateId'
    })
  },
  watch: {
    ordersMadeSelectedStateSlug(_) {
      this.setCurrentOrderStatePos();
    },
    orderStates(n, o) {
      this.setCurrentOrderStatePos();
    }
  },
  created: function() {
    this.setCurrentOrderStatePos();
  },
  updated: function() {},
  methods: {
    setCurrentOrderStatePos() {
      let currentOrderStateOrder;
      for (let i = 0; i < this.orderStates.length; i++) {
        const state = this.orderStates[i];
        if (state.slug == this.ordersMadeSelectedStateSlug) currentOrderStateOrder = state.order;
      }

      let statesData = this.orderStates
        .filter(state => state.slug != 'order_cancelled')
        .filter(state => {
          return this.ordersMadeSelectedStateSlug == 'quote_ready' || this.ordersMadeSelected.order_state.order < 3 //quote_approved
            ? state.slug != 'quote_approved'
            : state.slug != 'quote_ready';
        })
        .filter(state => {
          return this.ordersMadeSelectedStateSlug == 'approval_date_time' || this.ordersMadeSelected.order_state.order < 6 //date_time_approved
            ? state.slug != 'date_time_approved'
            : state.slug != 'approval_date_time';
        })
        .map(
          function(state) {
            if (currentOrderStateOrder > state.order) {
              return {
                name: state.name,
                class: `complete state-${state.slug}`
              };
            } else if (currentOrderStateOrder == state.order) {
              return {
                name: state.name,
                class: `active state-${state.slug} ${state.slug == 'quote_approved' || state.slug == 'date_time_approved' ? 'green' : ''} ${
                  state.slug == 'quote_ready' || state.slug == 'approval_date_time' ? 'red' : ''
                }`
              };
            } else {
              return {
                name: state.name,
                class: 'disabled state-' + state.slug
              };
            }
          }.bind(this)
        );
      this.statesData = statesData;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
/*Form Wizard*/
.bs-wizard {
  padding: 0 0 10px 0;
  border-bottom: none;
  overflow: hidden;
  margin: 20px -70px 20px -44px;
}
.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
  width: 19.6%;
  float: left;
}
//.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #595959;
  font-size: 16px;
  margin-bottom: 5px;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #595959;
  font-size: 12px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  pointer-events: none;
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: darken($lightBlue, 10%);
  top: 25px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: ' ';
  width: 14px;
  height: 14px;
  background: $brightBlue;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
  left: 50%;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: darken($lightBlue, 10%);
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #f5f5f5;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}
//.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 0%;
}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.bs-wizard {
  .complete {
    .bs-wizard-dot {
      pointer-events: all;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .green {
    .bs-wizard-dot {
      background: lighten($green, 20%);
      &:after {
        background: $green;
      }
    }
  }
  .red {
    .bs-wizard-dot {
      background: lighten($red, 30%);
      &:after {
        background: $red;
      }
    }
  }
}
</style>
