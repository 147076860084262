<template>
  <div class="order-selected-client well">
    <div class="form-group">
      <div class="data-display title-client"><strong>Company:</strong> {{ ordersMadeSelected.client.company_name }}</div>
      <div><strong>Address:</strong> {{ ordersMadeSelected.client.company_address }}</div>
    </div>
    <div class="form-group">
      <label for="client_name">Contact Person</label>
      <div>{{ ordersMadeSelected.client.first_name }} {{ ordersMadeSelected.client.last_name }}</div>
      <div>{{ ordersMadeSelected.client.email }}</div>
      <div>{{ ordersMadeSelected.client.phone_number }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderClientDisplay',
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
    }),
    ...mapMutations({}),
  },
  methods: {
    ...mapActions({}),
  },
  created: function () {},
  updated: function () {},
  mounted: function () {},
};
</script>

<style lang="css">
</style>
