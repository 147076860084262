<template>
  <div id="accordionOrder" class="order-status-wrapper">
    <div class="package-wrapper">
      <a class="package-header no-hover"> Quote Approval </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="row">
          <div class="col-sm-8 col-sm-offset-2">
            <!-- approve buttons -->
            <div v-if="ordersMadeSelectedStateSlug == 'quote_ready'">
              <div class="mb-2 alert alert-info">
                Your order quote is ready for approval, please scroll down and review the details. Use the approve button below to approve your quote.
              </div>
              <div class="approve-order-buttons mt-4">
                <button class="btn btn-danger" style="min-width: 200px; font-weight: 600" @click="showCancelOrderModal()">
                  Cancel Order
                </button>
                <button
                  class="btn btn-info "
                  style="min-width: 200px; font-weight: 600"
                  @click="showApproveQuoteModal()"
                  v-tooltip.notrigger.top="{ content: 'Order is ready for approval', visible: setOrderTooltip }"
                >
                  Approve
                </button>
              </div>
            </div>
            <div class="alert alert-success" v-else>
              Thank you for approving your quote! We'll send you an email notification as soon as your invoice is ready for payment.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- APPROVE MODAL -->
    <div id="approveQuoteModal" class="modal fade" role="dialog">
      <div class="modal-dialog" style="max-width: 420px">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Order Approval</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning" style="text-align:center">
              <div class="mr-1 mb-2" style="font-size: 18px;">Total Order Price:</div>
              <div style="font-size: 18px">{{ currency }} {{ ordersMadeSelected.order_data.orderTotalPriceWithTaxes }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="approveQuoteLocal()">Approve</button>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL CANCEL ORDER -->
    <div id="cancelOrderModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Cancel Order</h4>
          </div>
          <div class="modal-body">Are you sure you would like to cancel your order?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancelOrderUserLocal()">Cancel Order</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as helpers from '@/helpers/functions.js';

export default {
  components: {},
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      currency: 'currency',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug'
    })
  },
  data() {
    return {
      helpers,
      orderStateName: null,
      setOrderTooltip: false
    };
  },
  destroyed: function() {
    helpers.removeModalFromBody('approveOrderModal');
    helpers.removeModalFromBody('cancelOrderModal');
  },

  methods: {
    ...mapActions({
      saveSelectedOrderUser: 'saveSelectedOrderUser',
      getOrdersStatesCount: 'getOrdersStatesCount'
    }),
    approveQuoteLocal() {
      this.ordersMadeSelected.oldState = this.ordersMadeSelected.order_state.name;
      this.$store.commit('setTransLoader', true);
      const payload = new FormData();
      payload.append('id', this.ordersMadeSelected.id);
      payload.append('order_state_slug', 'quote_approved');
      payload.append('order_state_id', '3');
      this.saveSelectedOrderUser(payload).then(data => {
        this.getOrdersStatesCount();
        this.$store.commit('setTransLoader', false);
        this.$snotify.info('Order quote approved', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
      });
    },
    async cancelOrderUserLocal() {
      //loader
      this.$store.commit('setTransLoader', true);

      const payload = new FormData();
      payload.append('id', this.ordersMadeSelected.id);

      this.$store
        .dispatch('cancelOrderUser', payload)
        .then(async data => {
          this.$router.push({ name: 'OrdersAll' });
          this.$snotify.success('Order ' + data.order_number + ' canceled and removed', '', {
            timeout: 5000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightTop'
          });
        })
        .finally(() => {
          this.$store.commit('setTransLoader', false);
        });
    },
    showApproveQuoteModal() {
      this.setOrderTooltip = false;
      jQuery('#approveQuoteModal')
        .appendTo('body')
        .modal('show');
    },
    showCancelOrderModal() {
      this.setOrderTooltip = false;
      jQuery('#cancelOrderModal')
        .appendTo('body')
        .modal('show');
    }
  }
};
</script>

<style></style>
