<template>
  <div id="order-status-main-page">
    <Navigation />

    <div class="dashboard-container-right">
      <!-- LOADER -->
      <div v-if="!ordersMadeSelected.order_number" class="loader-dashboard-page" style="margin-top: 30px"><div></div></div>
      <!-- MAIN BODY -->
      <div class="order-selected" v-if="ordersMadeSelected.order_number">
        <!-- HEADER -->
        <div class="header-wrapper mt-3">
          <!-- TITLE -->
          <div class="order-selected-order-number">
            <button
              type="button"
              class="btn btn-default pull-left back-button-title mt-0"
              @click="unselectOrderMadeLocal()"
              v-tooltip.top="'Back to order list'"
            >
              <strong><i class="glyphicon glyphicon-chevron-left"></i></strong>
            </button>
            <h2 class="title mt-0">Order: {{ ordersMadeSelected.order_number }}</h2>
          </div>

          <div class="order-selected-header-end mt-2">
            <!-- ORDER STATUS CHIP -->
            <div
              v-if="selectedOrderStateLocal"
              class="data-display state-display state-display-admin"
              v-bind:class="'state-' + ordersMadeSelectedStateSlug"
            >
              {{ selectedOrderStateLocal }}
            </div>
            <!-- Action buttons admin and accounting -->
            <div class="order-selected-buttons-wrapper ml-4" v-if="isDashboardAdmin || isAccounting">
              <!-- edit buttons -->
              <div class="btn-group pull-right btn-group-lg" role="group" v-if="ordersMadeSelectedData.edit || ordersMadeSelectedData.editAccounting">
                <button type="button" class="btn btn-default first" title="Cancel" @click="cancelEditSelectedOrderLocal()">Cancel</button>
                <button type="button" class="btn btn-success" title="Save" v-if="ordersMadeSelectedData.edit" @click="checkForStateChangesLocal()">
                  Save
                </button>
                <button type="button" class="btn btn-success" title="Save" v-if="ordersMadeSelectedData.editAccounting" @click="sendEstimate()">
                  Send Invoice
                </button>
              </div>
              <!-- none edit state buttons -->
              <div class="edit-btn-actions" v-else style="display: flex; align-items: center">
                <a
                  class="mr-3 edit-order-icon"
                  href="#"
                  @click.prevent="isAccounting ? editSelectedOrderAccounting() : editSelectedOrder()"
                  v-tooltip.top="'Edit Order'"
                  ><i style="font-size: 24px" class="glyphicon glyphicon-edit"></i
                ></a>
                <!-- DROPDOWN MENU -->
                <div class="dropdown" v-if="isDashboardAdmin">
                  <button
                    class="btn btn-default dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <i class="glyphicon glyphicon-option-vertical"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li>
                      <a href="#" @click.prevent="editSelectedOrder()"> <i class="glyphicon glyphicon-edit"></i> Edit Order </a>
                    </li>
                    <li v-if="ordersMadeSelectedStateSlug == 'completed'">
                      <a href="#" @click.prevent="duplicateOrderLocal()"> <i class="glyphicon glyphicon-duplicate"></i> Repeat Order </a>
                    </li>

                    <li><OrderExportPdfButton></OrderExportPdfButton></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Action buttons client -->
            <div class="order-selected-buttons-wrapper ml-4" v-if="isClient && ordersMadeSelectedStateSlug == 'completed'">
              <a class="mr-3 edit-order-icon" href="#" @click.prevent="duplicateOrderLocal()" v-tooltip.top="'Repeat Order'"
                ><i style="font-size: 24px" class="glyphicon glyphicon-duplicate"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <hr />

        <!-- PEARLS -->
        <OrderStatesPearls />

        <!-- CREATED CLIENT -->
        <OrderCreatedMessage v-if="ordersMadeSelectedStateSlug == 'order_created' && isClient" />

        <!-- APPROVE QUOTE CLIENT -->
        <ApproveQuote v-if="(ordersMadeSelectedStateSlug == 'quote_ready' || ordersMadeSelectedStateSlug == 'quote_approved') && isClient" />

        <!-- APPROVE DATE TIME  CLIENT-->
        <ApproveOrderDateTime
          v-if="(ordersMadeSelectedStateSlug == 'approval_date_time' || ordersMadeSelectedStateSlug == 'date_time_approved') && isClient"
        />

        <!-- RESULTS CLIENT -->
        <OrderResultsUser v-if="ordersMadeSelectedStateSlug == 'completed' && isClient" />

        <!-- ORDER INFO ADMIN-->
        <OrderInfoAdmin v-if="isDashboardAdmin" :allowedToSaveApprovalDateTimeStep="allowedToSaveApprovalDateTimeStep" />

        <!-- INVOICE -->
        <OrderInvoice v-if="invoiceSectionVisible" />

        <!-- RESULTS ADMIN -->
        <OrderResults v-if="isDashboardAdmin" />

        <!-- INVOICE DATA ADMIN -->
        <OrderInvoiceData v-if="isDashboardAdmin || isAccounting" />

        <!-- ORDER INFO -->
        <OrderPackagesDisplay />

        <!-- ADDITIONAL COSTS -->
        <OrderAdditionalCosts />

        <!-- ORDER COMMENT -->
        <OrderComment />

        <!-- TOTAL COSTS -->
        <OrderTotalCosts />

        <!-- STATE CHANGE MODAL -->
        <div id="orderStateChangeModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">State Changed</h4>
              </div>
              <div class="modal-body">
                Order Status changed from: <br />
                <div class="state-change">
                  <div class="old-state">{{ oldState }}</div>
                  <div>to</div>
                  <div class="new-state">{{ newState }}</div>
                </div>
                <div class="service-date-warning" v-if="showDateWarningModal && this.ordersMadeSelected.order_state_id >= 3">
                  <div v-if="ordersMadeSelected.service_date_time">
                    Service Date and Time <br />
                    {{ helpers.formatDateTime(ordersMadeSelected.service_date_time) }}
                  </div>
                  <div v-else>No service date and time set</div>
                </div>
                <div class="clearfix"></div>
                <div class="sent-mail-wrapper">
                  <MaterialCheckbox
                    class="material-checkbox"
                    :selected="ordersMadeSelected.order_data.stateChangeMail"
                    @checkboxChanged="checkBoxClickedEmail($event)"
                  >
                  </MaterialCheckbox>
                  <span class="label-check"> Sent Email to client</span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="saveSelectedOrderLocal()">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Navigation from '@/components/generic/Navigation.vue';

//calender
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import OrderPackagesDisplay from './components/OrderPackagesDisplay.vue';
import OrderAdditionalCosts from './components/OrderAdditionalCosts.vue';
import OrderTotalCosts from './components/OrderTotalCosts.vue';
import OrderResults from './components/OrderResults.vue';
import OrderResultsUser from '@/components/pages/orders/OrderSelected/components/OrderResultsUser.vue';
import OrderStatesPearls from './components/OrderStatesPearls.vue';
import OrderExportPdfButton from './components/OrderExportPdfButton.vue';
import MaterialCheckbox from '../../../generic/MaterialCheckbox.vue';
import OrderCreatedMessage from './components/OrderCreatedMessage.vue';
import OrderInvoiceData from './components/OrderInvoiceData.vue';
import * as helpers from '../../../../helpers/functions.js';
import ApproveOrderDateTime from './components/ApproveOrderDateTime.vue';
import ApproveQuote from './components/ApproveQuote.vue';
import OrderInvoice from './components/OrderInvoice.vue';
import OrderInfoAdmin from './components/OrderInfoAdmin.vue';
import OrderComment from './components/OrderComment.vue';

export default {
  name: 'OrderSelected',
  data() {
    return {
      helpers,
      orderStateName: null,
      calenderConfig: {
        enableTime: true,
        altInput: true,
        altFormat: 'd-M-Y | h:i K'
      },
      oldState: null,
      newState: null,
      selected: true,
      showDateWarningModal: false,
      showEmailAssignWorker: true,
      fileToUpload: null
    };
  },
  components: {
    OrderInvoiceData,
    flatPickr,
    OrderPackagesDisplay,
    OrderAdditionalCosts,
    OrderTotalCosts,
    OrderResults,
    OrderResultsUser,
    OrderExportPdfButton,
    MaterialCheckbox,
    OrderStatesPearls,
    Navigation,
    ApproveOrderDateTime,
    OrderCreatedMessage,
    ApproveQuote,
    OrderInvoice,
    OrderInfoAdmin,
    OrderComment
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedCached: 'ordersMadeSelectedCached',
      ordersMadeSelectedPackages: 'ordersMadeSelectedPackages',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      orderStates: 'orderStates',
      isClient: 'isClient',
      selectedStateNameOrderMade: 'selectedStateNameOrderMade',
      pager: 'pager',
      additionalCostsSelectedOrderMade: 'additionalCostsSelectedOrderMade',
      resultsFile: 'resultsFile',
      isDashboardAdmin: 'isDashboardAdmin',
      dashboardAdmins: 'dashboardAdmins',
      ordersMadeSelectedStateId: 'ordersMadeSelectedStateId',
      getOrderData: 'getOrderData',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug',
      isAccounting: 'isAccounting'
    }),
    selectedOrderStateLocal() {
      let state = this.orderStates.filter(state => state.id == this.ordersMadeSelected.order_state.id)[0];
      return state.name;
    },
    invoiceSectionVisible() {
      if (this.ordersMadeSelected.order_state.order >= 3 && (this.isDashboardAdmin || this.isAccounting)) return true;
      else if (this.ordersMadeSelected.order_state.order >= 4 && this.isClient) return true;
      else return false;
    },
    selectedStateSlug() {
      let selectedState = this.orderStates.find(state => state.id == this.ordersMadeSelected.order_state_id);
      return selectedState ? selectedState.slug : null;
    },
    allowedToSaveApprovalDateTimeStep() {
      return this.currentStateNotAllowedToSave(['approval_date_time', 'date_time_approved', 'completed']);
    },
    allowedToSaveInvoiceReadyStep() {
      return this.currentStateNotAllowedToSave(['approval_date_time', 'date_time_approved', 'invoice_ready', 'completed']);
    },
    allowedToSaveCompletedStep() {
      return this.currentStateNotAllowedToSave(['completed']);
    }
  },
  methods: {
    ...mapMutations({
      setOrderTotalTaxes: 'setOrderTotalTaxes',
      checkBoxClickedEmail: 'checkBoxClickedEmail',
      checkBoxClickedAssWorkerEmail: 'checkBoxClickedAssWorkerEmail',
      selectOrderFromCalendar: 'selectOrderFromCalendar',
      clearAssignedWorker: 'clearAssignedWorker',
      duplicateOrder: 'duplicateOrder'
    }),
    ...mapActions({
      getOrdersMade: 'getOrdersMade',
      getUserData: 'getUserData',
      unselectOrderMade: 'unselectOrderMade',
      editSelectedOrder: 'editSelectedOrder',
      cancelEditSelectedOrder: 'cancelEditSelectedOrder',
      updateOrderState: 'updateOrderState',
      updateServiceDate: 'updateServiceDate',
      saveSelectedOrder: 'saveSelectedOrder',
      getOrdersStatesCount: 'getOrdersStatesCount',
      removeAdditionalCost: 'removeAdditionalCost',
      getOrderStates: 'getOrderStates',
      getorderResults: 'getorderResults',
      getInvoiceData: 'getInvoiceData',
      editSelectedOrderAccounting: 'editSelectedOrderAccounting'
    }),
    cancelEditSelectedOrderLocal() {
      this.getOrdersMade(0);
      this.cancelEditSelectedOrder();
      this.resetLocalOrderStates();
    },
    async saveSelectedOrderLocal() {
      this.cleanUpAdditionalCosts();
      //loader
      this.$store.commit('setTransLoader', true);

      const payload = new FormData();
      payload.append('id', this.ordersMadeSelected.id);
      payload.append('order_state_id', this.ordersMadeSelected.order_state_id);
      payload.append('order_previous_status_id', this.ordersMadeSelectedCached.order_state_id);
      payload.append('order_previous_status_name', this.oldState);
      payload.append('service_date_time', this.ordersMadeSelected.service_date_time);
      payload.append('assigned_worker_id', this.ordersMadeSelected.assigned_worker_id);
      payload.append('order_data', JSON.stringify(this.ordersMadeSelected.order_data));
      payload.append('order_packages', JSON.stringify(this.ordersMadeSelected.order_packages));
      payload.append('state_change_mail', this.ordersMadeSelected.order_data.stateChangeMail);

      this.saveSelectedOrder(payload)
        .then(async data => {
          this.getOrdersStatesCount();
          this.resetLocalOrderStates();
          // this.checkBoxClickedAssWorkerEmail(false);
          // this.showEmailAssignWorker = false;
          if (data.order_state.slug == 'order_cancelled') {
            this.orderCancelled(data);
          } else {
            this.$snotify.success('Order ' + data.order_number + ' saved', '', {
              timeout: 5000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop'
            });
          }
          if (data.mail) setTimeout(() => this.emailNotification('Notification email sent to client: ' + data.mail), 1000);
          if (data.mailAssigendWorker)
            setTimeout(() => this.emailNotification('Notification email sent to assigned worker: ' + data.mailAssigendWorker), 1000);
        })
        .finally(() => {
          this.$store.commit('setTransLoader', false);
        });
    },
    cleanUpAdditionalCosts() {
      this.additionalCostsSelectedOrderMade.forEach((cost, i) => {
        if (!cost.cost && i > 0) this.removeAdditionalCost(i);
      });
    },
    unselectOrderMadeLocal() {
      this.$router.push('/orders').catch(() => {});
      this.unselectOrderMade();
    },
    checkForStateChangesLocal() {
      //if client rejected date have to show modal again to sent new date
      let clientRejectedDateTime = this.ordersMadeSelected.order_data.clientRejectedDateTime;
      //if no changes made
      if (JSON.stringify(this.ordersMadeSelected) === JSON.stringify(this.ordersMadeSelectedCached) && !clientRejectedDateTime) {
        console.log('no changes');
        this.cancelEditSelectedOrderLocal();
        return;
      }

      //if order pending approval and no service data and time (validations)
      let noServiceDateTime = this.checkForServiceDateTime();
      let noAssignedWorker = this.checkForAssignedWorker();
      let noInvioceDateSet = this.checkIfInvoiceDateSet();
      let noResultFile = this.checkForResultFile();
      let noInvoiceFile = this.checkForInvoiceFile();

      if (noServiceDateTime || noAssignedWorker || noInvioceDateSet || noResultFile || noInvoiceFile) return;

      let newStateId = this.ordersMadeSelected.order_state_id;
      let oldStateId = this.ordersMadeSelectedCached.order_state_id;

      this.newState = this.orderStates.filter(state => {
        return state.id == newStateId;
      });
      this.oldState = this.orderStates.filter(state => {
        return state.id == oldStateId;
      });
      this.newState = this.newState[0].name;
      this.oldState = this.oldState[0].name;

      if (newStateId == oldStateId && !clientRejectedDateTime) {
        this.saveSelectedOrderLocal();
      } else {
        jQuery('#orderStateChangeModal')
          .appendTo('body')
          .modal('show');
        if (this.selectedStateSlug == 'approval_date_time') {
          this.showDateWarningModal = true;
        } else this.showDateWarningModal = false;
      }
    },
    sendEstimate() {
      //TODO: validate if date and estimae file present
      this.updateOrderState('4'); //invoice ready
      this.checkForStateChangesLocal();
    },
    emailNotification(data) {
      if (data) {
        this.$snotify.info(data, '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
      }
    },
    checkForServiceDateTime() {
      if (this.allowedToSaveApprovalDateTimeStep && !this.ordersMadeSelected.service_date_time) {
        this.$snotify.warning('Please set an order date and time', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
        return true;
      }
      return false;
    },
    checkForAssignedWorker() {
      if (this.allowedToSaveApprovalDateTimeStep && !this.ordersMadeSelected.assigned_worker_id) {
        this.$snotify.warning('Please set an assigned worker to the order', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
        return true;
      }
      return false;
    },
    checkIfInvoiceDateSet() {
      if (this.allowedToSaveInvoiceReadyStep && !this.ordersMadeSelected.order_data?.orderInvoiceDate?.normal) {
        this.$snotify.warning('Please set invoice date', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
        return true;
      }
      return false;
    },
    checkForResultFile() {
      if (this.allowedToSaveCompletedStep && !this.ordersMadeSelected.result_files?.result_file) {
        this.$snotify.warning('Please upload result file', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
        return true;
      }
      return false;
    },
    checkForInvoiceFile() {
      if (this.allowedToSaveInvoiceReadyStep && !this.ordersMadeSelected.invoice_files?.invoice_file) {
        this.$snotify.warning('Please upload invoice file', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
        return true;
      }
      return false;
    },
    resetLocalOrderStates() {
      this.newState = null;
      this.oldState = null;
    },
    currentStateNotAllowedToSave(statesNotAllowed) {
      const matchingState = statesNotAllowed.find(state => state === this.selectedStateSlug);
      return matchingState !== undefined;
    },
    duplicateOrderLocal() {
      this.duplicateOrder();
      this.$router.push('/create-order');
      this.$snotify.info(`Order duplicated`, '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        position: 'rightTop'
      });
    },
    getInvoiceDataLocal() {
      this.getInvoiceData()
        .then(data => {})
        .catch(error => {
          console.log(error);
          helpers.displayError(error + ': Invoice data', 'getInvoiceDataLocal', this);
        });
    },
    orderCancelled(orderData) {
      this.$router.push({ name: 'OrdersAll' });
      this.$snotify.success('Order ' + orderData.order_number + ' canceled and removed', '', {
        timeout: 5000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop'
      });
    }
  },
  async created() {
    if (this.orderStates.length == 0) await this.getOrderStates();
    await this.$store.dispatch('getSelectedOrder', this.$route.params.orderNumber);
    if (!this.getOrderData.adminCosts) this.getInvoiceDataLocal();
    this.getOrdersStatesCount();
    this.setOrderTotalTaxes();
    // this.checkBoxClickedAssWorkerEmail(false);
    this.selectOrderFromCalendar(false);
  },
  destroyed: function() {
    helpers.removeModalFromBody('orderStateChangeModal');
    helpers.removeModalFromBody('confirmToExport');
  }
};
</script>
