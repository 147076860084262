<template>
  <div>
    <h2 class="section-title">Invoice Data</h2>
    <div class="row order-selected-data">
      <div class="col-sm-6 weblab-form">
        <OrderClientDisplay></OrderClientDisplay>
      </div>
      <div class="weblab-form col-sm-6">
        <div class="well">
          <div class="form-group">
            <label>Invoice Date</label>

            <div v-if="ordersMadeSelectedData.edit || ordersMadeSelectedData.editAccounting">
              <flat-pickr
                @input="setInvoiceDateLocal($event)"
                v-model="ordersMadeSelected.order_data.orderInvoiceDate.normal"
                :config="calenderConfigDueDate"
                input-class="form-control"
              >
              </flat-pickr>
            </div>
            <div v-else class="data-display">
              {{ ordersMadeSelected.order_data.orderInvoiceDate.formatted }}
              <span v-if="!ordersMadeSelected.order_data.orderInvoiceDate.formatted"> No date and time set yet</span>
            </div>
          </div>
          <div class="form-group">
            <div v-if="ordersMadeSelectedData.edit || ordersMadeSelectedData.editAccounting">
              <label>Due in amount of days</label>
              <input
                type="number"
                class="form-control due-days"
                @change="setDueDateDaysLocal($event.target.value)"
                :value="ordersMadeSelected.order_data.orderInvoiceDueDays"
              />
              <div class="due-date-small">
                {{ ordersMadeSelected.order_data.orderInvoiceDueDate }}
              </div>
            </div>
            <template v-else>
              <label>Invoice Due Date</label>
              <div class="data-display">
                {{ ordersMadeSelected.order_data.orderInvoiceDueDate }}
                <span v-if="!ordersMadeSelected.order_data.orderInvoiceDueDate"> No date and time set yet</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderClientDisplay from './OrderClientDisplay.vue';
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
//calender
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    OrderClientDisplay,
    flatPickr
  },
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedPackages: 'ordersMadeSelectedPackages',
      ordersMadeSelectedData: 'ordersMadeSelectedData'
    }),
    currentSelectedOrderState() {
      return this.ordersMadeSelected.order_state_id;
    }
  },
  watch: {
    // currentSelectedOrderState(n, o) {
    //   if (!this.ordersMadeSelected.order_data.orderInvoiceDate.formatted && n == '4') {
    //     this.setInvoiceDateLocal(moment().format('YYYY-M-DD'));
    //   }
    // }
  },
  data() {
    return {
      calenderConfigDueDate: {
        enableTime: false,
        altInput: true,
        altFormat: 'd-M-Y'
      }
    };
  },
  methods: {
    ...mapMutations({
      setInvoiceDate: 'setInvoiceDate',
      setDueDateDays: 'setDueDateDays',
      setDueDate: 'setDueDate'
    }),
    setInvoiceDateLocal(data) {
      let days = this.ordersMadeSelected.order_data.orderInvoiceDueDays;
      this.setInvoiceDate({ dueDate: data, formatted: moment(data).format('DD-MMM-YYYY') });
      this.setDueDate(
        moment(data)
          .add(days, 'd')
          .format('DD-MMM-YYYY')
      );
    },
    setDueDateDaysLocal(data) {
      if (data) {
        this.setDueDateDays(data);
        this.setInvoiceDateLocal(this.ordersMadeSelected.order_data.orderInvoiceDate.normal);
      } else return;
    }
  }
};
</script>

<style></style>
