<template>
  <div id="accordionOrder" class="order-status-wrapper">
    <div class="package-wrapper">
      <a class="package-header no-hover"> Order Info </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="row">
          <div class="col-sm-6 weblab-form">
            <!-- ORDER STATUS -->
            <div class="form-group status-wrapper">
              <label class="mb-1">Order Status</label>
              <div v-if="!ordersMadeSelectedData.edit" class="data-display">
                {{ ordersMadeSelected.order_state.name }}
              </div>
              <div v-if="!orderStates.length" class="loader-medium"></div>
              <select
                id="select-package"
                v-model="ordersMadeSelected.order_state_id"
                class="form-control"
                :disabled="!ordersMadeSelectedData.edit"
                v-if="ordersMadeSelectedData.edit && orderStates.length"
                @change="updateOrderStateLocal($event.target.value)"
              >
                <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.name }}</option>
              </select>
            </div>

            <!-- DATE AND TIME -->
            <transition name="scale">
              <div class="form-group assigned-worker" v-if="allowedToSaveApprovalDateTimeStep">
                <div class="form-group service-time-data-wrapper">
                  <label>Order Service Date and Time</label>
                  <div v-if="!ordersMadeSelectedData.edit" class="data-display">
                    <span v-if="ordersMadeSelected.service_date_time">
                      {{ helpers.formatDateTime(ordersMadeSelected.service_date_time) }}
                    </span>
                    <span v-else> No date and time set yet</span>
                  </div>
                  <div v-if="ordersMadeSelectedData.edit">
                    <flat-pickr
                      @input="updateServiceDateLocal($event)"
                      v-model="ordersMadeSelected.service_date_time"
                      :config="calenderConfig"
                      input-class="form-control"
                      placeholder="Select date and time"
                    >
                    </flat-pickr>
                  </div>
                  <div class="alert alert-danger mt-3" style="display: flex" v-if="ordersMadeSelected.order_data.clientRejectedDateTime">
                    <i class="glyphicon glyphicon-warning-sign mr-2"></i>
                    <div>
                      <div>Client rejected date and time.</div>
                      <div class="small">Please edit this order and selected another date and time and save the order.</div>
                    </div>
                  </div>
                </div>
                <!-- ASSIGNED WORKER -->
                <AssignedWorker :showEmailAssignWorker="allowedToSaveApprovalDateTimeStep" />
              </div>
            </transition>
          </div>
          <div class="col-sm-6 weblab-form"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Navigation from '@/components/generic/Navigation.vue';
import AssignedWorker from '@/components/pages/orders/OrderSelected/components/AssignedWorker.vue';

//calender
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import * as helpers from '../../../../../helpers/functions.js';

export default {
  name: 'OrderSelected',
  props: ['allowedToSaveApprovalDateTimeStep'],
  data() {
    return {
      helpers,
      calenderConfig: {
        enableTime: true,
        altInput: true,
        altFormat: 'd-M-Y | h:i K'
      },
      showEmailAssignWorker: true
    };
  },
  components: {
    flatPickr,
    AssignedWorker
  },
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      orderStates: 'orderStates',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug'
    }),
    selectedStateSlug() {
      let selectedState = this.orderStates.find(state => state.id == this.ordersMadeSelected.order_state_id);
      return selectedState ? selectedState.slug : null;
    }
  },
  methods: {
    ...mapMutations({
      setOrderTotalTaxes: 'setOrderTotalTaxes',
      checkBoxClickedEmail: 'checkBoxClickedEmail',
      checkBoxClickedAssWorkerEmail: 'checkBoxClickedAssWorkerEmail',
      selectOrderFromCalendar: 'selectOrderFromCalendar',
      clearAssignedWorker: 'clearAssignedWorker',
      duplicateOrder: 'duplicateOrder'
    }),
    ...mapActions({
      getOrdersMade: 'getOrdersMade',
      getUserData: 'getUserData',
      unselectOrderMade: 'unselectOrderMade',
      editSelectedOrder: 'editSelectedOrder',
      cancelEditSelectedOrder: 'cancelEditSelectedOrder',
      updateOrderState: 'updateOrderState',
      updateServiceDate: 'updateServiceDate',
      saveSelectedOrder: 'saveSelectedOrder',
      getOrdersStatesCount: 'getOrdersStatesCount',
      removeAdditionalCost: 'removeAdditionalCost',
      getOrderStates: 'getOrderStates',
      getorderResults: 'getorderResults',
      getInvoiceData: 'getInvoiceData',
      editSelectedOrderAccounting: 'editSelectedOrderAccounting'
    }),
    updateOrderStateLocal(data) {
      if (
        this.selectedStateSlug == 'order_created' ||
        this.selectedStateSlug == 'quote_ready' ||
        this.selectedStateSlug == 'quote_approved' ||
        this.selectedStateSlug == 'invoice_ready'
      ) {
        this.clearAssignedWorker();
      }

      //show assigned worker email check box only on pending approval and approved
      this.selectedStateSlug == 'approval_date_time' || this.selectedStateSlug == 'date_time_approved'
        ? (this.showEmailAssignWorker = true)
        : (this.showEmailAssignWorker = false);

      this.updateOrderState(data);
    },
    updateServiceDateLocal(data) {
      if (!data) this.updateServiceDate({ date: null, date_formatted: null });
      else this.updateServiceDate({ date: data, date_formatted: moment(data).format('DD-MMM-YYYY | h:mm A') });
    }
  }
};
</script>
