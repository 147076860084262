<template>
  <div class="package-wrapper">
    <div class="package-header">Additional Costs</div>
    <div class="panel-collapse">
      <div class="order-additional-costs">
        <!-- no add. costs message -->
        <div v-if="!ordersMadeSelectedData.edit && +getSelectedOrderData.additionalCostsTotal == 0" class="alert alert-warning">
          If there are additional costs, they will be added here.
        </div>

        <template v-else>
          <!-- table add. costs -->
          <table class="table table-bordered text-caption table-condensed mt-2 mb-0">
            <thead>
              <tr>
                <th>Description</th>
                <th>Hour Rate</th>
                <th>Hours</th>
                <th>Cost ({{ currency }})</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cost, index) in additionalCostsSelectedOrderMade" :key="index">
                <td>
                  <div v-if="ordersMadeSelectedData.edit">
                    <textarea
                      class="form-control description"
                      :value="cost.description"
                      v-on:keyup="setDescription({ description: $event.target.value, index: index })"
                      type="text"
                      placeholder="Fill in description"
                    ></textarea>
                  </div>
                  <div v-else class="data-display description-display" v-html="cost.description"></div>
                </td>
                <td>
                  <div v-if="ordersMadeSelectedData.edit">
                    <input
                      class="form-control"
                      :value="cost.hourRate"
                      v-on:keyup="setHourRate({ hourRate: $event.target.value, index: index })"
                      type="text"
                      placeholder="eg. 75"
                    />
                  </div>
                  <div v-else class="data-display">{{ cost.hourRate }}</div>
                </td>
                <td>
                  <div v-if="ordersMadeSelectedData.edit">
                    <input
                      class="form-control"
                      :value="cost.hours"
                      v-on:keyup="setHours({ hours: $event.target.value, index: index })"
                      type="text"
                      placeholder="eg. 10"
                    />
                  </div>
                  <div v-else class="data-display">{{ cost.hours }}</div>
                </td>
                <td>
                  <div v-if="ordersMadeSelectedData.edit" :style="'display:flex;align-items:center'">
                    <input class="form-control cost" :value="cost.cost" type="text" disabled />

                    <button class="ml-2 btn-danger btn" @click="removeAdditionalCost(index)" v-if="index > 0 && ordersMadeSelectedData.edit">
                      <div aria-hidden="true" style="font-size:20px">&times;</div>
                    </button>
                  </div>
                  <div v-else class="data-display cost-display">{{ cost.cost }}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="total-selected ">
            <span class="pull-right">
              <span class="text-caption mr-1 mb-4" style="color:grey"> Total additional costs:</span> {{ currency }} {{ totalAdditionalCosts }}</span
            >
            <div class="clearfix"></div>
          </div>

          <!-- add button -->
          <button @click="addCost()" v-if="ordersMadeSelectedData.edit" class="btn btn-default pull-right" title="Add another additional costs">
            <i class="glyphicon glyphicon-plus"></i>
          </button>
        </template>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderAdditionalCosts',
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      getSelectedOrderData: 'getSelectedOrderData',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      additionalCostsSelectedOrderMade: 'additionalCostsSelectedOrderMade',
      currency: 'currency'
    }),
    totalAdditionalCosts() {
      return this.additionalCostsSelectedOrderMade.reduce((acc, cost) => acc + +cost.cost, 0);
    }
  },
  methods: {
    ...mapActions({
      setDescription: 'setDescription',
      addCost: 'addCost',
      setHourRate: 'setHourRate',
      setHours: 'setHours',
      removeAdditionalCost: 'removeAdditionalCost'
    })
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
