<template>
  <div class="order-invoice-wrapper">
    <div class="package-wrapper">
      <a class="package-header no-hover"> Order Results </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="row">
          <div class="col-sm-6 col-left col-sm-offset-3">
            <div v-if="ordersMadeSelected.result_files && ordersMadeSelected.result_files.result_file">
              <div class="alert alert-success">
                <div class="mb-3">Results ready for download</div>

                <a class="btn-success btn w-100" @click="downloadResultFile" :disabled="preparingFileForSave">
                  <span v-if="preparingFileForSave"><i class="glyphicon glyphicon-hourglass"></i> Downloading...</span>
                  <span v-else><i class="glyphicon glyphicon-cloud-download"></i> Download Results</span>
                </a>
              </div>
            </div>
            <div v-else>
              <div class="data-display">Once your order is completed you will be able to download your results here.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  name: 'OrderResultsUser',
  data() {
    return {
      showMessage: false,
      preparingFileForSave: false
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      getSelectedOrderData: 'getSelectedOrderData',
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedData: 'ordersMadeSelectedData',
      resultsFile: 'resultsFile',
      resultsFileToUpload: 'resultsFileToUpload',
      ordersMadeSelectedStateId: 'ordersMadeSelectedStateId'
    })
  },
  methods: {
    ...mapMutations({
      setResultFileToUpload: 'setResultFileToUpload',
      unselectResultFileToUpload: 'unselectResultFileToUpload'
    }),
    ...mapActions({}),
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].type !== 'application/pdf') {
        this.$snotify.error(`File must be in a PDF format`, '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'rightTop'
        });
        document.getElementById('file-upload').value = '';
        return;
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.setResultFileToUpload({ file: e.target.result, fileName: file.name });
        this.diplaySaveMessage();
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      document.getElementById('file-upload').value = '';
    },
    diplaySaveMessage() {
      this.showMessage = true;
      setTimeout(() => (this.showMessage = false), 3000);
    },
    removeCurrentFileLocal() {
      this.setResultFileToUpload({ file: 'delete', fileName: null });
    },
    downloadResultFile() {
      this.preparingFileForSave = true;
      axios({
        url: `${this.$store.state.config.baseUrlApi}/api/download-resut-file`,
        data: {
          file_path: this.ordersMadeSelected.result_files.result_file.file_path,
          saved_file_name: this.ordersMadeSelected.result_files.result_file.saved_file_name,
          order_id: this.ordersMadeSelected.id
        },
        method: 'POST',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.ordersMadeSelected.order_number + '_webnv_OLP-results.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.preparingFileForSave = false;
        });
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
