<template>
  <div class="order-date-time-wrapper">
    <div class="package-wrapper">
      <a class="package-header no-hover">Approve order date and time: </a>
      <div id="collapse-order-status" class="panel-collapse collapse in">
        <div class="row">
          <div class="col-sm-offset-2 col-sm-8">
            <div v-if="ordersMadeSelectedStateSlug == 'approval_date_time'">
              <!-- rejected alert -->
              <div v-if="ordersMadeSelected.order_data.clientRejectedDateTime" class="alert alert-warning">
                Order service date and time has been rejected. You will be notified through email when a new date and time is set.
              </div>
              <!-- approve buttons -->
              <div v-else>
                <div class="alert alert-info">
                  <div class="mb-2">
                    Your order has been set date and time has been set to: <br />
                    <strong> {{ helpers.formatDateTime(ordersMadeSelected.service_date_time) }}</strong>
                  </div>
                  <div class="mb-2">
                    To approve this, click the approve button below. Or click the reject button and another service date and time will be proposed.
                  </div>
                </div>
                <div class="button-wrapper">
                  <button class="btn btn-danger btn-approve mt-1 mr-3" style="min-width: 200px; font-weight: 600" @click="showRejectModal()">
                    Reject
                  </button>
                  <button
                    class="btn btn-info btn-approve mt-1"
                    style="min-width: 200px; font-weight: 600"
                    @click="showApproveModal()"
                    v-tooltip.notrigger.top="{ content: 'Order is ready for approval', visible: setOrderTooltip }"
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-info">
                Thank you for approving the order service date and time. </br>Your order date and time is set to:
                <strong> {{ helpers.formatDateTime(ordersMadeSelected.service_date_time) }}</strong>

              </div>
              <div class="small">Once the order has been carried out and completed you will be notified and be able to download your results here.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- APPROVE MODAL -->
    <div id="approveOrderModal" class="modal fade" role="dialog">
      <div class="modal-dialog" style="max-width: 420px">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Order Approval</h4>
          </div>
          <div class="modal-body">
            <div>Your order date and time will be set to:</div>
            <div class="date-time">{{ helpers.formatDateTime(ordersMadeSelected.service_date_time) }}</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="approveOrderLocal()">Confirm</button>
          </div>
        </div>
      </div>
    </div>

    <!-- REJECT MODAL -->
    <div id="rejectOrderModal" class="modal fade" role="dialog">
      <div class="modal-dialog" style="max-width: 620px">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header danger">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Reject date and time</h4>
          </div>
          <div class="modal-body">
            <div>
              Are you sure you want to reject the date and time? <br />
              <br />
              <div class="price-warning pt-3 pb-3">
                A new date and time will be set. Once the new date and time is set, you will receive an email notification prompting you to approve
                the new service date and time on the {{ $store.state.config.siteName }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="rejectDateTimeLocal()">Reject</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as helpers from '@/helpers/functions.js';
export default {
  props:['oldState'],
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      ordersMadeSelectedStateSlug: 'ordersMadeSelectedStateSlug'
    })
  },
  data() {
    return {
      helpers,
      orderStateName: null,
      setOrderTooltip: false
    };
  },
  destroyed: function() {
    helpers.removeModalFromBody('approveOrderModal');
    helpers.removeModalFromBody('rejectOrderModal');
  },

  methods: {
    ...mapActions({
      saveSelectedOrderUser: 'saveSelectedOrderUser',
      getOrdersStatesCount: 'getOrdersStatesCount'
    }),
    approveOrderLocal() {
      this.ordersMadeSelected.oldState = this.ordersMadeSelected.order_state.name;
      this.$store.commit('setTransLoader', true);
      const payload = new FormData();
      payload.append('id', this.ordersMadeSelected.id);
      payload.append('order_state_id', 6); //order approved
      payload.append('order_previous_status_name', 'Approval Date/Time');
      this.saveSelectedOrderUser(payload).then(data => {
        this.getOrdersStatesCount();
        this.$store.commit('setTransLoader', false);
        this.$snotify.info('Order service date approved', '', {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop'
        });
      });
    },
    rejectDateTimeLocal() {
      this.$store.commit('setTransLoader', true);
      const payload = new FormData();
      payload.append('id', this.ordersMadeSelected.id);
      this.$store.dispatch('clientRejectedDateTime', payload).then(_ => {
        this.getOrdersStatesCount();
        this.$store.commit('setTransLoader', false);
        // this.$snotify.info('Order service date approved', '', {
        //   timeout: 5000,
        //   showProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   position: 'rightTop',
        // });
      });
    },
    showApproveModal() {
      this.setOrderTooltip = false;
      jQuery('#approveOrderModal')
        .appendTo('body')
        .modal('show');
    },
    showRejectModal() {
      this.setOrderTooltip = false;
      jQuery('#rejectOrderModal')
        .appendTo('body')
        .modal('show');
    }
  }
};
</script>

<style></style>
