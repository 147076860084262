<template>
  <div class="row">
    <div class=" col-md-12">
      <div class="alert alert-warning mb-2">
        <label><strong>Order Created:</strong></label>
        <div>Your order has been created. Once processed, you will receive a notification email to approve your service date and time.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
