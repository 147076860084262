<template>
  <div class="package-wrapper">
    <div class="package-header">Final Price</div>
    <div class="panel-collapse">
      <div class="order-total-costs">
        <div class="total-order-wrapper pull-right text-right">
          <div class="order-price">Order Price: {{ currency }} {{ getTotalCostOrder }}</div>
          <div class="small order-price">Admin Costs: {{ currency }} {{ computedAdminCosts }}</div>
          <!-- <div class="small taxes bbo">BBO ({{ taxes.bbo }}%): {{ currency }} {{ ordersMadeSelected.order_data.orderTotalPriceBBO }}</div>
      <div class="small taxes bazv">BAZV ({{ taxes.bazv }}%): {{ currency }} {{ ordersMadeSelected.order_data.orderTotalPriceBAZV }}</div>
      <div class="small taxes bavp">BAZP ({{ taxes.bavp }}%): {{ currency }} {{ ordersMadeSelected.order_data.orderTotalPriceBAVP }}</div> -->

          <div class="total  mt-4">
            <span class="mr-1" style="font-size: 18px;">Total Order Price: </span>
            {{ currency }} {{ ordersMadeSelected.order_data.orderTotalPriceWithTaxes }}
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderTotalCosts',
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      getTotalCostOrder: 'getTotalCostOrder',
      currency: 'currency',
      ordersMadeSelected: 'ordersMadeSelected',
      taxes: 'taxes'
    }),
    computedAdminCosts() {
      if (this.ordersMadeSelected.order_data.adminCosts) return this.ordersMadeSelected.order_data.adminCosts.toFixed(2);
      else return null;
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      setOrderTotalTaxes: 'setOrderTotalTaxes'
    })
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="css"></style>
